@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

/* SPOQA 폰트 적용 */
* {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
}

@tailwind base;

/* Write your own custom base styles here */

/* Start purging... */
@tailwind components;
/* Stop purging. */

/* Write you own custom component styles here */

/* Start purging... */
@tailwind utilities;
/* Stop purging. */

/* Your own custom utilities */
