.markdown {
  @apply text-lg leading-relaxed;
}

.markdown p,
.markdown ul,
.markdown ol,
.markdown blockquote {
  @apply my-6;
  margin: 20px 0;
}

.markdown p {
  color: #363636;
  font-weight: 300;
  line-height: 180%;
}

.markdown h2 {
  @apply text-3xl mt-12 mb-4 leading-snug;
}

.markdown h3 {
  @apply text-2xl mt-8 mb-4 leading-snug;
}

/* 이하 모바일 */
.mobile {
  @apply text-base leading-relaxed;
}

.mobile p,
.mobile ul,
.mobile ol,
.mobile blockquote {
  @apply my-6;
  margin: 20px 0;
}

.mobile p {
  color: #363636;
  font-weight: 300;
  line-height: 180%;
}

.mobile h2 {
  @apply text-2xl mt-12 mb-4 leading-snug;
}

.mobile h3 {
  @apply text-xl mt-8 mb-4 leading-snug;
}
.mobile img {
  width: 100%;
}
